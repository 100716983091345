import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Say `}<a parentName="p" {...{
        "href": "mailto:hasanbayat1393@gmail.com"
      }}>{`Hi`}</a>{` or find me on other platforms: `}<a parentName="p" {...{
        "href": "https://dribbble.com/hasanbayat"
      }}>{`Dribbble`}</a>{` | `}<a parentName="p" {...{
        "href": "https://www.twitter.com/hasanbayat_me"
      }}>{`Twitter`}</a>{` | `}<a parentName="p" {...{
        "href": "https://www.instagram.com/hasanbayat_me"
      }}>{`Instagram`}</a>{` | `}<a parentName="p" {...{
        "href": "https://www.github.com/hasanbayatme"
      }}>{`GitHub`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      