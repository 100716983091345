import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Open Source" link="https://www.github.com/hasanbayatme" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  I've created and contributed to a handful of open source projects that you can find them at my profile.
    </ProjectCard>
    <ProjectCard title="Bayat Games" link="https://www.github.com/BayatGames" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Create and play games passionately with joy.
    </ProjectCard>
    <ProjectCard title="Music" link="https://soundcloud.com/hasanbayat_me" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Beautifuly crafted collection of music and tracks by me.
    </ProjectCard>
    <ProjectCard title="Videos" link="https://www.youtube.com/channel/UC9VwStxVvq2hkun5-doDwpQ" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Carefuly curated and created videos for more than entertainment.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      